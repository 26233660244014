<template v-slot:body>

    <app-modal v-show="isAddModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveBill">

            <div v-if="showMessage == true" v-html="message"></div>

            <app-form-row label="Subcategory" id="billSubcat">
                <select
                    required
                    v-model="bill.subcategory_id"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    @keydown.enter.prevent="saveBill"
                >
                    <option value="" selected disabled>Select Subcategory</option>
                    <optgroup v-for="(subcategories, category) in groupedSubcategories" :label="category" :key="category">
                        <option v-for="subcategory in subcategories" :value="subcategory.id" :key="subcategory.id">
                        {{ subcategory.sub }}
                        </option>
                    </optgroup>
                </select>
            </app-form-row>
            
            <app-form-row label="Day of the month" id="billDay">
                <input
                    required
                    type="number"
                    min="1"
                    max="31"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    class="date-mobile-full mt-1 block rounded-md h-10 py-2 pl-4 pr-2 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    v-model="bill.day"
                    @keydown.enter.prevent="saveBill">
            </app-form-row>
            <div class="text-white leading-5 text-xs text-left italic mt-0 mb-4">If you enter a day beyond the maximum for the current month, it'll switch to the last day of that month.</div>
            
            <app-form-row label="Value" id="billValue">
                <input
                    required
                    type="number"
                    class="date-mobile-full mt-1 block rounded-md h-10 py-2 pl-4 pr-2 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    v-model="bill.value"
                    @keydown.enter.prevent="saveBill">
            </app-form-row>
            
            <app-form-row label="Name" id="billName">
                <input
                    required
                    type="text"
                    class="date-mobile-full mt-1 block rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    v-model="bill.name"
                    @keydown.enter.prevent="saveBill">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveBill"
            >
                Save Recurring Bill
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isAddModalOpen: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const subcategoriesList = ref([]);
        const bill = ref([]);
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Add New Recurring Bill');
        const modalBody = ref('');
        const isSaving = ref(false);

        const closeModal = () => {
            emit('update:isAddModalOpen', false);
        };

        const fetchSubcategories = () => {
            axios.get('/backend/api/get-subcategories.php')
            .then((response) => {
                // console.log(response.data);
                subcategoriesList.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };

        const groupedSubcategories = computed(() => {
            const grouped = {};
            subcategoriesList.value.forEach((subcategory) => {
                if (!grouped[subcategory.cat]) {
                    grouped[subcategory.cat] = [];
                }
                grouped[subcategory.cat].push(subcategory);
            });
            return grouped;
        });

        const saveBill = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const billData = {
                subcat: bill.value.subcategory_id,
                day: bill.value.day,
                value: bill.value.value,
                name: bill.value.name
            };
            axios.post('/backend/api/add-recurring-bill.php', billData)
            .then(response => {
                if (response.data.success === true) {
                    emit('bill-saved'); // tell view it was saved so it will refresh the tables

                    // clear all
                    bill.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('Recurring bill added successfully!');
                } else {
                    console.error('Failed to create recurring bill.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to create recurring bill!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error adding recurring bill!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        // Watcher to fetch categories when modal opens
        watch(() => props.isAddModalOpen, (isOpen) => {
            if (isOpen) {
                fetchSubcategories();
            }
        });

        return {
            subcategoriesList,
            bill,
            showMessage,
            message,
            modalHeading,
            modalBody,
            closeModal,
            saveBill,
            groupedSubcategories,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>