<template v-slot:body>

    <app-modal v-show="isAddModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveCategory">

            <div v-if="showMessage == true" v-html="message"></div>

            <app-form-row label="Category Name" id="addCategoryName">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="addCategoryName"
                    type="text"
                    v-model="category.category_name"
                    @keydown.enter.prevent="saveCategory"
                    required>
            </app-form-row>
            
            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveCategory"
            >
                Save Category
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isAddModalOpen: {
            type: Boolean,
            required: true
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const category = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Add New Category');
        const modalBody = ref('');
        const isSaving = ref(false);

        const closeModal = () => {
            emit('update:isAddModalOpen', false);
        };

        const saveCategory = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const categoryData = {
                categoryName: category.value.category_name,
            };

            axios.post('/backend/api/add-category.php', categoryData)
            .then(response => {
                if (response.data.success === true) {
                    emit('category-saved'); // tell view it was saved so it will refresh the tables

                    // clear all
                    category.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('Category added successfully!');
                } else {
                    console.error('Failed to create category.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to create category!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error adding category!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        return {
            category,
            showMessage,
            message,
            modalHeading,
            modalBody,
            closeModal,
            saveCategory,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>