<template>

    <!-- Loader -->
    <app-loader :loading="loading" />

    <div class="py-2 px-4 bg-gray-800 sm:px-8">

        <h1 class="flex pt-3 pb-8 items-center border-b border-gray-700 mb-8 sm:pt-6">
            <div class="text-2xl font-bold text-gray-200 sm:text-3xl">Setup</div>
            <span class="ml-auto text-1xl font-bold text-red-500 px-4 py-2
                inline-flex justify-center
                shadow-sm ring-1 ring-inset ring-gray-900
                rounded-md bg-gray-900/70"
            >
                <span v-if="userId == 1">GOD Only</span>
                <span v-else>Admin Only</span>
            </span>
        </h1>

        <!-- /NOT God level -->
        <h2 v-if="userId > 1" class="flex flex-col items-start gap-2 text-xl font-bold text-white py-2 sm:flex-row sm:items-center">
            User Info
        </h2>
        <div v-if="userId > 1" class="flex flex-col gap-3 rounded-md text-white p-7 mb-16 bg-gray-900/50 shadow-sm ring-1 ring-inset ring-gray-900">
            <div v-if="currentUser.value">
                <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white">
                    <span class="font-black">ID:</span> {{ currentUser.value.user_id }}
                </div>

                <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white">
                    <span class="font-black">Name:</span> {{ currentUser.value.name }}
                </div>

                <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white">
                    <span class="font-black">Username:</span> {{ currentUser.value.username }}
                </div>

                <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white">
                    <span class="font-black">Email:</span> {{ currentUser.value.email }}
                </div>
            </div>
            <button
                type="submit"
                class="primary sm:w-fit"
                @click="editUser(currentUser.value)"
            >
                Edit
            </button>
        </div>
        <!-- NOT God level/ -->

        <h2 class="flex flex-col items-start gap-2 text-xl font-bold text-white py-2 sm:flex-row sm:items-center">
            Categories
            <button
                type="submit"
                class="primary sm:w-fit w-full sm:ml-auto"
                @click="addCategory()"
                >
                Add a New Category
            </button>
            <!-- ml-auto mt-1  -->
        </h2>

        <app-table
            v-if="categoriesList.length > 0"
            :columns="tableCategoriesColumns"
            :data="categoriesList"
            :show-actions="true"
            :actions-column-label="actionsColumnLabel"
            :sortable="false"
            @category-saved="refreshTables"
            class="pb-16"
        >
            <template v-slot:actions="{ item }">
                <!-- {{ item.category_id }} -->
                <div class="flex flex-row gap-4 justify-end">
                    <button
                        @click="editCategory(item)"
                        :label="item.category_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-sky-700 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0 sm:bg-transparent
                               sm:hover:bg-transparent sm:hover:text-sky-400"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">edit</span>
                        <span class="text-base sm:hidden">Edit</span>
                    </button>
                    <button
                        @click="deleteCategory(item)"
                        :label="item.category_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-transparent text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0
                               sm:hover:bg-transparent sm:hover:text-red-700"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">delete_forever</span>
                        <span class="text-base sm:hidden">Delete</span>
                    </button>
                </div>
            </template>
        </app-table>
        <div v-else class="p-4 mb-16 bg-gray-700 text-white rounded-md text-center py-16">Nothing to show yet!</div>

        <h2 class="flex flex-col items-start gap-2 text-xl font-bold text-white py-2 sm:flex-row sm:items-center">
            Subcategories
            <button
                type="submit"
                class="primary sm:w-fit w-full sm:ml-auto"
                @click="addSubcategory()"
                >
                Add a New Subcategory
            </button>
        </h2>

        <app-table
            v-if="groupedSubcategoriesList.length > 0"
            :columns="tableSubcategoriesColumns"
            :data="groupedSubcategoriesList"
            :show-actions="true"
            :actions-column-label="actionsColumnLabel"
            :sortable="false"
            @subcategory-saved="refreshTables"
            class="pb-16"
        >
            <template v-slot:actions="{ item }">
                <!-- {{ item.subcategory_id }} -->
                <div class="flex flex-row gap-4 justify-end">
                    <button
                        @click="editSubcategory(item)"
                        :label="item.subcategory_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-sky-700 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0 sm:bg-transparent
                               sm:hover:bg-transparent sm:hover:text-sky-400"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">edit</span>
                        <span class="text-base sm:hidden">Edit</span>
                    </button>
                    <button
                        @click="deleteSubcategory(item)"
                        :label="item.subcategory_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-transparent text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0
                               sm:hover:bg-transparent sm:hover:text-red-700"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">delete_forever</span>
                        <span class="text-base sm:hidden">Delete</span>
                    </button>
                </div>
            </template>
        </app-table>
        <div v-else class="p-4 mb-16 bg-gray-700 text-white rounded-md text-center py-16">Nothing to show yet!</div>

        <h2 class="flex flex-col items-start gap-2 text-xl font-bold text-white py-2 sm:flex-row sm:items-center">
            Recurring Bills
            <div class="flex flex-col gap-2 ml-auto w-full sm:flex-row sm:w-auto">
                <button
                    type="submit"
                    class="gray sm:w-fit w-full sm:ml-auto"
                    @click="autoRecurringBill()"
                    >
                    Automation
                </button>
                <button
                    type="submit"
                    class="primary sm:w-fit w-full sm:ml-auto"
                    @click="addRecurringBill()"
                    >
                    Add a New Recurring Bill
                </button>
            </div>
        </h2>

        <app-table
            v-if="billsList.length > 0"
            :columns="tableBillsColumns"
            :data="billsList"
            :show-actions="true"
            :actions-column-label="actionsColumnLabel"
            :sortable="false"
            @bill-saved="refreshTables"
            :row-class-condition="isStatusZero"
            class="pb-16"
        >
            <template v-slot:actions="{ item }">
                <!-- {{ item.recurring_id }} -->
                <div class="flex flex-row gap-4 justify-end">
                    <button
                        @click="editRecurringBill(item)"
                        :label="item.recurring_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-sky-700 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0 sm:bg-transparent
                               sm:hover:bg-transparent sm:hover:text-sky-400"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">edit</span>
                        <span class="text-base sm:hidden">Edit</span>
                    </button>
                    <button
                        @click="deleteRecurringBill(item)"
                        :label="item.recurring_id"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-transparent text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0
                               sm:hover:bg-transparent sm:hover:text-red-700"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">delete_forever</span>
                        <span class="text-base sm:hidden">Delete</span>
                    </button>
                </div>
            </template>
        </app-table>
        <div v-else class="p-4 mb-16 bg-gray-700 text-white rounded-md text-center py-16">Nothing to show yet!</div>

        <!-- /God level only -->
        <div v-if="userId == 1">
            <h2 class="flex flex-col items-start gap-2 text-xl font-bold text-white py-2 sm:flex-row sm:items-center">
                Users
                <button
                    type="submit"
                    class="primary sm:w-fit w-full sm:ml-auto"
                    @click="addUser()"
                    >
                    Add a New User
                </button>
            </h2>

            <app-table
                v-if="usersList.length > 0"
                :columns="tableUsersColumns"
                :data="usersList"
                :show-actions="true"
                :actions-column-label="actionsColumnLabel"
                :sortable="false"
                @user-saved="refreshTables"
                class="pb-16"
            >
                <template v-slot:actions="{ item }">
                    <!-- {{ item.user_id }} -->
                    <div class="flex flex-row gap-4 justify-end">
                        <button
                            v-if="item.user_id > 1"
                            @click="editUser(item)"
                            :label="item.user_id"
                            class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-sky-700 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700
                                   sm:w-auto sm:p-0 sm:m-0 sm:ring-0 sm:bg-transparent
                                   sm:hover:bg-transparent sm:hover:text-sky-400"
                        >
                            <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">edit</span>
                            <span class="text-base sm:hidden">Edit</span>
                        </button>
                        <button
                            v-if="item.user_id > 1"
                            @click="deleteUser(item)"
                            :label="item.user_id"
                            class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-transparent text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500
                                   sm:w-auto sm:p-0 sm:m-0 sm:ring-0
                                   sm:hover:bg-transparent sm:hover:text-red-700"
                        >
                            <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">delete_forever</span>
                            <span class="text-base sm:hidden">Delete</span>
                        </button>
                    </div>
                </template>
            </app-table>
            <div v-else class="p-4 mb-16 bg-gray-700 text-white rounded-md text-center py-16">Nothing to show yet!</div>
        </div>
        <!-- God level only/ -->

        <!-- Add Category Modal -->
        <add-category-modal
            ref="addCategoryModal"
            :is-add-modal-open="isAddCategoryModalOpen"
            @update:is-add-modal-open="isAddCategoryModalOpen = $event"
            @category-saved="refreshTables"
        />

        <!-- Edit Category Modal -->
        <edit-category-modal
            ref="editCategoryModal"
            :is-edit-modal-open="isEditCategoryModalOpen"
            @update:is-edit-modal-open="isEditCategoryModalOpen = $event"
            @category-saved="refreshTables"
            :selected-category="selectedCategory"
        />

        <!-- Add Subcategory Modal -->
        <add-subcategory-modal
            ref="addSubcategoryModal"
            :is-add-modal-open="isAddSubcategoryModalOpen"
            @update:is-add-modal-open="isAddSubcategoryModalOpen = $event"
            @subcategory-saved="refreshTables"
        />

        <!-- Edit Subcategory Modal -->
        <edit-subcategory-modal
            ref="editSubcategoryModal"
            :is-edit-modal-open="isEditSubcategoryModalOpen"
            @update:is-edit-modal-open="isEditSubcategoryModalOpen = $event"
            @subcategory-saved="refreshTables"
            :selected-subcategory="selectedSubcategory"
        />

        <!-- Add Recurring Bills Modal -->
        <add-bill-modal
            ref="addBillModal"
            :is-add-modal-open="isAddBillModalOpen"
            @update:is-add-modal-open="isAddBillModalOpen = $event"
            @bill-saved="refreshTables"
        />

        <!-- Edit Recurring Bills Modal -->
        <edit-bill-modal
            ref="editBillModal"
            :is-edit-modal-open="isEditBillModalOpen"
            @update:is-edit-modal-open="isEditBillModalOpen = $event"
            @bill-saved="refreshTables"
            :selected-bill="selectedBill"
        />

        <!-- Add User Modal -->
        <add-user-modal
            ref="addUserModal"
            :is-add-modal-open="isAddUserModalOpen"
            @update:is-add-modal-open="isAddUserModalOpen = $event"
            @user-saved="refreshTables"
        />

        <!-- Edit User Modal -->
        <edit-user-modal
            ref="editUserModal"
            :is-edit-modal-open="isEditUserModalOpen"
            @update:is-edit-modal-open="isEditUserModalOpen = $event"
            @user-saved="refreshTables"
            :selected-user="selectedUser"
        />

    </div>

</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import AppLoader from "@/components/common/AppLoader.vue";
import AppTable from "@/components/common/AppTable";
import AddCategoryModal from "@/components/setup/AddCategoryModal";
import EditCategoryModal from "@/components/setup/EditCategoryModal";
import AddSubcategoryModal from "@/components/setup/AddSubcategoryModal";
import EditSubcategoryModal from "@/components/setup/EditSubcategoryModal";
import AddBillModal from "@/components/setup/AddBillModal";
import EditBillModal from "@/components/setup/EditBillModal";
import AddUserModal from "@/components/setup/AddUserModal";
import EditUserModal from "@/components/setup/EditUserModal";

export default {
    components: {
        AppLoader,
        AppTable,
        AddCategoryModal,
        EditCategoryModal,
        AddSubcategoryModal,
        EditSubcategoryModal,
        AddUserModal,
        EditUserModal,
        AddBillModal,
        EditBillModal
    },
    setup() {
        // Reactive state
        const userId = ref(localStorage.getItem('userId'));
        const isAddCategoryModalOpen = ref(false);
        const isAddSubcategoryModalOpen = ref(false);
        const isAddUserModalOpen = ref(false);
        const isAddBillModalOpen = ref(false);
        const isEditCategoryModalOpen = ref(false);
        const isEditSubcategoryModalOpen = ref(false);
        const isEditUserModalOpen = ref(false);
        const isEditBillModalOpen = ref(false);
        // const refreshThis = ref(null);
        const selectedCategory = ref(null);
        const selectedSubcategory = ref(null);
        const selectedUser = ref(null);
        const selectedBill = ref(null);
        const loading = ref(false);
        const toast = useToast();

        // Reactive state for data
        const categoriesList = ref([]);
        const groupedSubcategoriesList = ref([]);
        const billsList = ref([]);
        const usersList = ref([]);
        const currentUser = reactive({});
        
        // Computed properties
        const actionsColumnLabel = ref('Actions');
        const tableCategoriesColumns = [
            { key: "category_name", label: "Category" }
        ];
        const tableSubcategoriesColumns = [
            { key: "category_name", label: "Category" },
            { key: "subcategory_name", label: "Subcategory" }
        ];
        const tableBillsColumns = [
            { key: "name", label: "Name" },
            { key: "subcategory_name", label: "Subcategory" },
            { key: "day", label: "Day of Month" },
            { key: "value", label: "Value" },
            { key: "status", label: "Status" }
        ];
        const tableUsersColumns = [
            { key: "user_id", label: "ID" },
            { key: "username", label: "Name" },
            { key: "email", label: "Email" }
        ];

        const isStatusZero = (item) => {
            return item.status === 0;
        };

        // Methods Category -------------------------------------------------------------
        const fetchCategories = () => {
            // Set loading to true before making the request
            loading.value = true;
            axios.post('/backend/api/get-categories.php')
                .then(response => {
                    // console.log(response.data);
                    categoriesList.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                })
                .finally(() => {
                    loading.value = false;
            });
        };

        const addCategory = () => {
            isAddCategoryModalOpen.value = true;
        };

        const editCategory = (category) => {
            selectedCategory.value = category;
            isEditCategoryModalOpen.value = true;
        };

        const deleteCategory = (category) => {
            // console.log('Delete category with ID:', category.category_id);
            if (window.confirm('Are you sure you want to delete this category?')) {
                axios.post('/backend/api/delete-category.php', { category })
                .then(response => {
                    if (response.data.success === true) {
                        refreshTables();
                        // console.log('Category deleted successfully!');
                        toast.success('Category deleted successfully!');
                    } else {
                        console.error('Failed to delete category.');
                    }
                })
                .catch(error => {
                    console.error('Error deleting category:', error);
                    toast.error('Error deleting category!');
                });
            }
        };

        // Methods Subcategory ----------------------------------------------------------
        const fetchSubcategories = () => {
            // Set loading to true before making the request
            loading.value = true;
            axios.post('/backend/api/get-setup-subcategories.php')
                .then(response => {
                    // console.log(response.data);
                    groupedSubcategoriesList.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching subcategories:', error);
                })
                .finally(() => {
                    loading.value = false;
            });
        };

        const addSubcategory = () => {
            isAddSubcategoryModalOpen.value = true;
        };
        
        const editSubcategory = (subcategory) => {
            // console.log('Edit subcategory with ID:', subcategory.subcategory_id);
            selectedSubcategory.value = subcategory;
            isEditSubcategoryModalOpen.value = true;
        };

        const deleteSubcategory = (subcategory) => {
            // console.log('Delete subcategory with ID:', subcategory.subcategory_id);
            if (window.confirm('Are you sure you want to delete this subcategory?')) {
                axios.post('/backend/api/delete-subcategory.php', { subcategory })
                .then(response => {
                    if (response.data.success === true) {
                        refreshTables();
                        // console.log('Subcategory deleted successfully!');
                        toast.success('Subcategory deleted successfully!',);
                    } else {
                        console.error('Failed to delete category.');
                    }
                })
                .catch(error => {
                    console.error('Error deleting subcategory:', error);
                    toast.error('Error deleting category!');
                });
            }
        };

        // Methods Recurring Bills ------------------------------------------------------
        const fetchRecurringBills = () => {
            // Set loading to true before making the request
            loading.value = true;
            axios.post('/backend/api/get-recurring-bills.php')
                .then(response => {
                    // console.log(response.data);
                    billsList.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching recurring bills:', error);
                })
                .finally(() => {
                    loading.value = false;
            });
        };

        const addRecurringBill = () => {
            isAddBillModalOpen.value = true;
        };
        
        const editRecurringBill = (bill) => {
            // console.log('Edit recurring bill with ID:', bill.recurring_id);
            selectedBill.value = bill;
            isEditBillModalOpen.value = true;
        };

        const deleteRecurringBill = (bill) => {
            // console.log('Delete recurring bill with ID:', bill.recurring_id);
            if (window.confirm('Are you sure you want to delete this recurring bill?')) {
                axios.post('/backend/api/delete-recurring-bills.php', { bill })
                .then(response => {
                    if (response.data.success === true) {
                        refreshTables();
                        // console.log('Recurring Bill deleted successfully!');
                        toast.success('Recurring Bill deleted successfully!');
                    } else {
                        console.error('Failed to delete recurring bill!');
                    }
                })
                .catch(error => {
                    console.error('Error deleting recurring bill!', error);
                    toast.error('Error deleting recurring bill!');
                });
            }
        };

        const autoRecurringBill = async () => {
            // console.log('Automation for recurring bill');
            try {
                // trigger the recurring bills generation
                const response = await axios.post('/backend/api/auto-recurring-bills.php?userId=' + userId.value);

                // Handle the response if needed
                console.log(response.data);

                // this.$root.$refs.toaster.addToast('Recurring bills generation!');
            } catch (error) {
                // Handle errors
                console.error('Error triggering recurring bills generation:', error);
                // this.$root.$refs.toaster.addToast('Error triggering recurring bill generation!');
            }
        };

        // Methods Users ----------------------------------------------------------------
        const fetchUsers = () => {
            // Set loading to true before making the request
            loading.value = true;
            
            axios.post('/backend/api/get-users.php')
            .then((response) => {
                // console.log(response.data);
                usersList.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            })
            .finally(() => {
                loading.value = false; // Set loading to false after the request completes (success or error)
            });
        };

        const addUser = () => {
            // console.log('Add user');
            isAddUserModalOpen.value = true;
        };

        const editUser = (user) => {
            // console.log('Edit user with ID:', user.user_id);
            selectedUser.value = user;
            isEditUserModalOpen.value = true;
        };

        const deleteUser = (user) => {
            // console.log('Delete user with ID:', user.user_id);
            if (window.confirm('Are you sure you want to delete this user?')) {
                axios.post('/backend/api/delete-user.php', { user })
                .then(response => {
                    if (response.data.success === true) {
                        refreshTables();
                        // console.log('Recurring Bill deleted successfully!');
                        toast.success('User deleted successfully!');
                    } else {
                        console.error('Failed to delete user!');
                    }
                })
                .catch(error => {
                    console.error('Error deleting user!', error);
                    toast.error('Error deleting user');
                });
            }
        };

        const fetchCurrentUser = () => {
            // Set loading to true before making the request
            loading.value = true;

            axios.get(`/backend/api/get-user-details.php?userId=${userId.value}`)
                .then((response) => {
                    currentUser.value = response.data; // Set directly for reactive
                })
                .catch((error) => {
                    console.error('Error fetching user details:', error);
                })
                .finally(() => {
                    loading.value = false; // Set loading to false after the request completes (success or error)
                });
        };

        // Refresh all tables ------------------------------------------------------
        const refreshTables = () => {
            fetchCurrentUser();
            fetchCategories();
            fetchSubcategories();
            fetchRecurringBills();

            // only fetch users if it GOD level user
            if(userId.value == 1) {
                fetchUsers();
            }
        };

        // Lifecycle hook
        onMounted(() => {
            refreshTables();
        });

        // Listen for 'user-details-updated' event from eventBus
        const eventBus = inject('eventBus');
        if (eventBus) {
            eventBus.$on('user-details-updated', (userData) => {
                currentUser.value = userData;
            });
        } else {
            console.error('eventBus is not injected properly.');
        }

        return {
            userId,
            isAddCategoryModalOpen,
            isAddSubcategoryModalOpen,
            isAddUserModalOpen,
            isAddBillModalOpen,
            isEditCategoryModalOpen,
            isEditSubcategoryModalOpen,
            isEditUserModalOpen,
            isEditBillModalOpen,
            selectedCategory,
            selectedSubcategory,
            selectedUser,
            selectedBill,
            loading,
            categoriesList,
            groupedSubcategoriesList,
            billsList,
            usersList,
            currentUser,
            actionsColumnLabel,
            tableCategoriesColumns,
            tableSubcategoriesColumns,
            tableBillsColumns,
            tableUsersColumns,
            fetchCategories,
            isStatusZero,
            addCategory,
            editCategory,
            deleteCategory,
            fetchSubcategories,
            addSubcategory,
            editSubcategory,
            deleteSubcategory,
            fetchRecurringBills,
            addRecurringBill,
            editRecurringBill,
            deleteRecurringBill,
            autoRecurringBill,
            fetchUsers,
            addUser,
            editUser,
            deleteUser,
            fetchCurrentUser,
            refreshTables
        };
    }
}
</script>
  
<style>
/* Add any custom styles specific to SetupView here */
</style>