<template>
    <button
        class="fixed z-40 bottom-4 right-4 bg-red-500 w-16 h-16 rounded-full
               drop-shadow-lg flex justify-center items-center text-white text-4xl
             hover:bg-red-700 hover:drop-shadow-2xl duration-300
               sm:bottom-6 sm:right-8"
    >
        <span class="material-symbols-outlined">add</span>
    </button>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
/* Add any custom styles specific to Pagination here */
</style>