<template>
    <div class="mb-3 sm:col-span-4">
        <label :for="id" class="block text-sm font-black leading-6 text-left text-white">{{ label }}</label>
        <slot></slot>
    </div>
</template>
  
<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
};
</script>
  
<style>
</style>