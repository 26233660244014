// src/router.js

import { createRouter, createWebHistory } from 'vue-router';
import LoginView from './views/LoginView.vue';
import DashboardView from './views/DashboardView.vue';
import ExpensesView from './views/ExpensesView.vue';
import ForgotUserView from './views/ForgotUserView.vue';
import NewUserView from './views/NewUserView.vue';
import SetupView from './views/SetupView.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: '/login', // Redirect root URL to the login page
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashboardView,
        },
        {
            path: '/expenses',
            name: 'expenses',
            component: ExpensesView,
        },
        {
            path: '/forgotuser',
            name: 'forgotuser',
            component: ForgotUserView,
        },
        {
            path: '/newuser',
            name: 'newuser',
            component: NewUserView,
        },
        {
            path: '/setup',
            name: 'setup',
            component: SetupView,
        },
    ],
});

// Add the navigation guard here
router.beforeEach((to, from, next) => {
    const isLoggedIn = !!localStorage.getItem('userId'); // Check if userId is present in localStorage
  
    if (isLoggedIn) {
        if (isSessionExpired()) {
            // Log out the user if the session has expired
            localStorage.removeItem('userId');
            localStorage.removeItem('expiration');
            next({ name: 'login' });
        } else {
            next(); // Proceed with navigation
        }
    } else {
        // Allow navigation to '/login', '/forgotuser', and '/newuser' when the user is not logged in
        if (to.name === 'login' || to.name === 'forgotuser' || to.name === 'newuser') {
            next();
        } else {
            next({ name: 'login' }); // Redirect to login page for other routes
        }
    }
});

function isSessionExpired() {
    const expiration = localStorage.getItem('expiration');
    if (!expiration) {
        return true; // Session has expired if the expiration timestamp is not available
    }

    const currentTimestamp = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds
    return currentTimestamp >= parseInt(expiration);
}

function checkSessionStatus() {
    if (isSessionExpired()) {
        // Log out the user if the session has expired
        localStorage.removeItem('userId');
        localStorage.removeItem('expiration');
        router.push({ name: 'login' });
    }
}

// Check the session status every minute (adjust as needed)
setInterval(checkSessionStatus, 60 * 1000);

export default router;
