<template v-slot:body>

    <app-modal v-show="isAddModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveExpense">

            <app-form-row label="Subcategory" id="subcat">
                <select
                    required
                    id="subcat"
                    name="subcat"
                    v-model="expense.subcat"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                >
                    <option value="" selected disabled>Select Subcategory</option>
                    <optgroup v-for="(subcategories, category) in groupedSubcategories" :label="category" :key="category">
                        <option v-for="subcategory in subcategories" :value="subcategory.id" :key="subcategory.id">
                        {{ subcategory.sub }}
                        </option>
                    </optgroup>
                </select>
            </app-form-row>

            <app-form-row label="Value" id="value">
                <input
                    type="number"
                    class="mt-1 block w-full rounded-md h-10 py-2 pl-4 pr-2 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="value"
                    id="value"
                    v-model="expense.value"
                    required
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>

            <app-form-row label="Note" id="note">
                <input
                    type="text"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="note"
                    id="note"
                    v-model="expense.note"
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>
            
            <app-form-row label="Date" id="date">
                <input
                    type="date"
                    name="date"
                    id="date"
                    class="date-mobile-full mt-1 block rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    v-model="expense.date"
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveExpense"
                >
                Save Expense
            </button>
        </template>
                
    </app-modal>

</template>

<script>
import { ref, computed, watch } from 'vue';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isAddModalOpen: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const subcategoriesList = ref([]);
        const expense = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Add New Expense');
        const modalBody = ref('');
        const isSaving = ref(false);

        // Computed methods
        const groupedSubcategories = computed(() => {
            const grouped = {};
            subcategoriesList.value.forEach((subcategory) => {
                if (!grouped[subcategory.cat]) {
                    grouped[subcategory.cat] = [];
                }
                grouped[subcategory.cat].push(subcategory);
            });
            return grouped;
        });
    
        const closeModal = () => {
            emit('update:isAddModalOpen', false);
        };

        const fetchSubcategories = () => {
            axios.get('/backend/api/get-subcategories.php')
            .then((response) => {
                // console.log(response.data);
                subcategoriesList.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };

        const saveExpense = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const expenseData = {
                subcat: expense.value.subcat,
                date: expense.value.date,
                note: expense.value.note,
                value: expense.value.value
            };
            // Make an AJAX request to save the expense data
            axios.post('/backend/api/add-expense.php', expenseData)
            .then(response => {
                if (response.data.success === true) {
                    emit('expense-actions'); // tell view it was saved so it will refresh the tables

                    // clear all
                    expense.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('Expense added successfully!');
                } else {
                    console.error('Failed to add expense.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to add expense! ' + response.data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error adding expense!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        // Watcher to fetch categories when modal opens
        watch(() => props.isAddModalOpen, (isOpen) => {
            if (isOpen) {
                fetchSubcategories();
            }
        });

        return {
            subcategoriesList,
            expense,
            showMessage,
            message,
            modalHeading,
            modalBody,
            groupedSubcategories,
            closeModal,
            fetchSubcategories,
            saveExpense,
            isSaving
        };
    }
};
</script>

<style>
.date-mobile-full {
    min-width: -webkit-fill-available;
}
</style>