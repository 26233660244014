import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

// Create an event bus instance
const eventBus = {
  listeners: {},
  $on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  },
  $emit(event, ...args) {
    if (this.listeners[event]) {
      this.listeners[event].forEach(callback => {
        callback(...args);
      });
    }
  },
};

app.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
  timeout: 6000,
  // timeout: false, // for testing
  draggable: false,
  maxToasts: 10
});

const toast = {
  clearToasts() {
    app.config.globalProperties.$toast.clear();
  },
  success(message) {
    this.clearToasts();
    app.config.globalProperties.$toast.success(message, {});
  },
  error(message) {
    this.clearToasts();
    app.config.globalProperties.$toast.error(message, {
      timeout: false, // Keep the toast until manually dismissed
    });
  },
  info(message) {
    this.clearToasts();
    app.config.globalProperties.$toast.info(message, {
      timeout: 10000,
    });
  },
  warning(message) {
    this.clearToasts();
    app.config.globalProperties.$toast.warning(message, {
      timeout: 10000,
    });
  },
};

app.provide('toast', toast);
app.provide('eventBus', eventBus); // Provide eventBus at the root level
app.use(router);
// app.use(Toast, {
//   position: POSITION.BOTTOM_LEFT, // Setting the global default position
//   // timeout: false,
//   timeout: 5000,
// });
app.mount('#app');
