<template>
    <div v-if="loading" class="fixed top-0 left-0 z-50 w-full h-full bg-gray-900/70 flex items-center justify-center">
        <!-- <span class="animate-spin loading-icon text-white material-symbols-outlined">progress_activity</span> -->
        <span class="animate-spin loading-icon"><svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FFFFFF"><path d="M480-80q-84 0-157-31t-127-85q-54-54-85-127T80-480q0-84 31-157t85-127q54-54 127-85t157-31q12 0 21 9t9 21q0 12-9 21t-21 9q-141 0-240.5 99.5T140-480q0 141 99.5 240.5T480-140q141 0 240.5-99.5T820-480q0-12 9-21t21-9q12 0 21 9t9 21q0 84-31 157t-85 127q-54 54-127 85T480-80Z"/></svg></span>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style>
.loading-icon {
    width: 48px;
    height: 48px;
}
</style>