<template>
    <div class="table-responsive">
        <table class="aa-table responsive">
            <thead>
                <tr>
                    <th scope="col" v-for="column in columns" :key="column.key">
                        <a href="#" v-if="column.sort === true" @click="updateSort(column.key)">
                            {{ column.label }}
                            <span v-if="sortOption === column.key" class="text-xs">&#x25B2;</span>
                            <span v-else class="text-xs">&#x25BC;</span>
                        </a>
                        <span v-else>
                            {{ column.label }}
                        </span>
                    </th>
                    <!-- Conditionally show the Actions column -->
                    <th v-if="showActions" :data-label="actionsColumnLabel" class="text-actions" scope="col">
                        {{ actionsColumnLabel }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, index) in sortedData"
                    :key="item.id"
                    :class="{ 'division': shouldHighlightRow(item, index) }"
                >
                    <td :data-label="column.label" v-for="column in columns" :key="column.key" :class="{ 'opacity-30 bg-gray-900/95': rowClassCondition(item) }">
                        <!-- hide this <a> on desktop, its only for mobile -->
                        <a href="#" v-if="column.sort === true" @click="updateSort(column.key)"> 
                            {{ item[column.key] }}
                        </a>
                        <!-- use v-html so the icon from DB is printed -->
                        <span v-else v-html="item[column.key]" class="inline-flex flex-row items-center gap-2"></span>
                    </td>
                    <td v-if="showActions" class="text-actions" :class="{ 'bg-gray-900/30': rowClassCondition(item) }">
                        <slot name="actions" :item="item"></slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        sortOption: {
            type: String,
            default: '',
        },
        sortDirection: {
            type: String,
            default: '',
        },
        showActions: {
            type: Boolean,
            default: false,
        },
        actionsColumnLabel: {
            type: String,
            default: 'Actions',
        },
        highlightLastRow: {
            type: Function,
            default: () => false,
        },
        rowClassCondition: {
            type: Function,
            default: () => false,
        },
    },
    data() {
        return {
            // Use local data properties to handle the sorting
            localSortOption: '',
            localSortDirection: 'DESC',
        };
    },
    computed: {
        sortedData() {
            // Check if sorting is enabled (sortable prop is true)
            if (!this.sortable) {
                return this.data; // Return the data as-is without sorting
            }

            // Retrieve the sortOption and sortDirection from the parent component's props
            const sortOption = this.localSortOption || this.sortOption;
            const sortDirection = this.localSortDirection || this.sortDirection;

            if (!sortOption || !sortDirection) {
                // Return the data without sorting if no sorting options are selected
                return this.data;
            }

            // Sort the data based on the selected sorting option and direction
            const sortedData = [...this.data];
            sortedData.sort((a, b) => {
                // Use numerical comparison for the "id" and "value" columns
                if (sortOption === 'id' || sortOption === 'value') {
                    const aValue = parseFloat(a[sortOption]);
                    const bValue = parseFloat(b[sortOption]);
                    return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
                } else {
                    // Adjust the sorting logic based on the data types in your columns
                    if (sortDirection === 'asc') {
                        return a[sortOption].localeCompare(b[sortOption]);
                    } else {
                        return b[sortOption].localeCompare(a[sortOption]);
                    }
                }
            });

            return sortedData;
        },
    },
    methods: {
        updateSort(option) {
            // Check if sorting is enabled (sortable prop is true)
            if (this.sortable) {
                if (this.localSortOption === option) {
                    this.localSortDirection =
                    this.localSortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.localSortOption = option;
                    this.localSortDirection = 'asc';
                }

                // Emit the sort-changed event with the updated sortOption and sortDirection
                this.$emit(
                    'sort-changed',
                    this.localSortOption,
                    this.localSortDirection
                );
            }
        },
        shouldHighlightRow(item, index) {
            // Check if the highlightLastRow prop is provided and is a function
            if (this.highlightLastRow && typeof this.highlightLastRow === 'function') {
                // Call the highlightLastRow function with the current item and its index
                return this.highlightLastRow(item, index);
            }
            // If highlightLastRow is not provided or not a function, return false (no highlight)
            return false;
        },
    },
};
</script>
  
<style>
/* Responsive TABLE */
.aa-table {
	width: 100%;
	border-collapse: collapse;
}

/* block columns that has data-label, regular columns to have click event, so it will only work on the edit delete buttons column */
.aa-table td:not([data-label='']) a {
	color: var(--bs-body-color);
	text-decoration: none;
	pointer-events: none;
}

.aa-table thead .material-symbols-outlined {
	vertical-align: middle;
	height: 1.8rem;
}

.aa-table thead tr th {
	vertical-align: middle;
	text-align: left;
    border-bottom: 4px solid rgb(55, 65, 81);
}

.aa-table thead tr th.text-actions {
	text-align: right;
}

/* From Bootstrap */
.aa-table {
	width: 100%;
	margin-bottom: 1rem;
	vertical-align: top;
}

.aa-table > :not(caption)>*>* {
	padding: 0.8rem 0.4rem 0.4rem 0.4rem;
	color: #FFF;
    border-bottom: 1px solid rgb(55, 65, 81);
}

.aa-table > tbody {
	vertical-align: inherit;
}

.aa-table tbody tr {
    background-color: rgba(55, 65, 81, 0);
    transition: all 0.3s;
}

.aa-table tbody tr:hover {
    background-color: rgba(55, 65, 81, 1);
}

.aa-table tbody tr.division {
    border-bottom: 4px solid rgb(55, 65, 81);
}

.aa-table > thead {
	vertical-align: bottom;
}

/* Responsive Table */
@media (max-width: 768px) {
	.aa-table td:not([data-label='']) a {
        color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
        text-decoration: underline;
        pointer-events: all;
    }

    .aa-table tbody tr.division {
        border: none;
    }

    .aa-table.responsive thead {
        visibility: hidden;
        height: 0;
        position: absolute;
    }

    .aa-table.responsive tr {
        display: block;
        margin-bottom: 1rem;
        border: rgba(17, 24, 39, 1) solid 1px;
        border-radius: 0.5rem;
        background-color: rgba(17, 24, 39, 0.5);
    }

    .aa-table.responsive td,
    .aa-table.responsive th {
        display: flex;
        flex-direction: column;
        border: none;
        font-size: 1rem;
        min-height: 3.2rem;
        line-height: 1.6rem;
        padding: 0.8rem 1.6rem;
    }

    .aa-table.responsive td:first-child,
    .aa-table.responsive th:first-child {
        padding: 1.6rem 1.6rem 0.8rem 1.6rem;
    }

    .aa-table.responsive td:last-child,
    .aa-table.responsive th:last-child {
        padding: 0.8rem 1.6rem 1.6rem 1.6rem;
    }

    .aa-table.responsive td::before {
        content: attr(data-label);
        font-weight: bold;
        font-size: 1.1rem;
    }

    .aa-table.responsive td:last-child a {
        padding: 0 0 0 1rem;
    }
}

</style>
  