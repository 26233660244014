<template>
    <nav class="isolate flex justify-center">
      <button 
        @click="updateCurrentPage(1)"
        :disabled="currentPage === 1"
        class="relative inline-flex items-center px-4 py-2 text-sm font-semibold
             bg-gray-900 text-white border-t border-r border-b border-l-0 border-gray-900 hover:bg-gray-600 rounded-l-md
             disabled:bg-gray-900/20 disabled:cursor-default"
      >
        &laquo;
      </button>
      <button 
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
        @click="updateCurrentPage(pageNumber)"
        :class="{
          'bg-red-500 text-white border-t border-r border-b border-l-0 hover:bg-red-600 border-gray-900': pageNumber === currentPage,
          'bg-gray-900 text-white border-t border-r border-b border-l-0 border-gray-900 hover:bg-gray-600': pageNumber !== currentPage
        }"
        class="relative inline-flex items-center px-4 py-2 text-sm font-semibold"
      >
        {{ pageNumber }}
      </button>
      <button 
        @click="updateCurrentPage(totalPages)"
        :disabled="currentPage === totalPages"
        class="relative inline-flex items-center px-4 py-2 text-sm font-semibold
             bg-gray-900 text-white border-t border-r border-b border-l-0 border-gray-900 hover:bg-gray-600 rounded-r-md
             disabled:bg-gray-900/20 disabled:cursor-default"
      >
        &raquo;
      </button>
    </nav>
  </template>
  
  <script>
  export default {
    props: {
      totalPages: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
      maxVisiblePages: {
        type: Number,
        default: 5, // Change this value based on your preference
      },
    },
    computed: {
      visiblePages() {
        const half = Math.floor(this.maxVisiblePages / 2);
        let start = Math.max(1, this.currentPage - half);
        let end = Math.min(this.totalPages, this.currentPage + half);
        
        // Adjust if at the start or end of the pagination
        if (this.currentPage - half < 1) {
          end = Math.min(this.totalPages, end + (half - (this.currentPage - 1)));
        }
        if (this.currentPage + half > this.totalPages) {
          start = Math.max(1, start - ((this.currentPage + half) - this.totalPages));
        }
  
        // Generate page numbers
        const pages = [];
        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
        return pages;
      },
    },
    methods: {
      updateCurrentPage(pageNumber) {
        this.$emit("update-current-page", pageNumber);
      },
    },
  };
  </script>
  
  <style>
  /* Add any custom styles specific to Pagination here */
  </style>