<template>
    <div class="h-full">
        <!-- Conditionally show the mainnavbar if the current route is not LoginView -->
        <main-navbar v-if="$route.name !== 'login' && $route.name !== 'forgotuser' && $route.name !== 'newuser'"></main-navbar>

        <!-- The router-view will render the appropriate component based on the current route -->
        <router-view></router-view>
    </div>
</template>

<script>
import MainNavbar from './components/common/MainNavbar.vue';

export default {
    name: 'App',
    components: {
        MainNavbar
    },
    data() {
        return { };
    },
    methods: { }
};
</script>

<style>
/* Global styles for the entire application */
</style>
