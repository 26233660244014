<template>
    <div
        v-show="isAddModalOpen"
        class="relative z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        @keydown.esc="closeModal"
        tabindex="0"
    >
        <div class="fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 sm:w-full">
                <div
                    class="relative w-11/12 transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all
                           sm:my-8 sm:w-1/3 sm:w-2xl">
                    <slot name="close">
                        <button
                            @click="closeModal"
                            class="close-modal"
                        >
                            <span class="material-symbols-outlined">close</span>
                        </button>
                    </slot>
                    <div class="bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div class="text-center sm:mt-0 sm:text-left">
                            <h3 class="text-1xl text-left font-semibold leading-6 text-gray-200 sm:text-2xl" id="modal-title">
                                {{ heading }}
                            </h3>
                            <div class="mt-8">
                                <slot class="text-sm text-gray-200">
                                    <!-- {{ body }} -->
                                </slot>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-800 px-4 py-4 border-t border-gray-700 sm:flex sm:flex-row-reverse sm:ml-auto">
                        <slot name="buttons">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        isAddModalOpen: {
            type: Boolean,
            default: false,
        },
        heading: String,
    },
    methods: {
        closeModal() {
            // console.log('-------isAddModalOpen:', this.isAddModalOpen);
            this.$emit('update:isAddModalOpen', false);
        },
    },
};
</script>

<style lang="postcss">
button.close-modal {
    @apply absolute right-2 top-4 flex items-center justify-center w-8 h-8 text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white sm:right-3 sm:top-6;
}
</style>