<template>

    <!-- Loader -->
    <app-loader :loading="loading" />

    <div class="py-2 px-4 bg-gray-800 sm:px-8">

        <h1 class="flex pt-3 pb-8 items-center border-b border-gray-700 mb-4 sm:pt-6">
            <div class="text-2xl font-bold text-gray-200 sm:text-3xl">Dashboard</div>
            <span
                v-if="totalView > 0"
                class="ml-auto text-1xl font-bold text-red-500 px-4 py-2
                    inline-flex justify-center
                    shadow-sm ring-1 ring-inset ring-gray-900
                    rounded-md bg-gray-900/70"
            >
                Total: ${{ totalView }}
            </span>
        </h1>

        <!-- Show message when there are no expenses -->
        <div v-if="totalView <= 0" class="p-4 mb-8 bg-gray-700 text-white rounded-md text-center py-16">
            <p v-html="noDataMessage"></p>
        </div>

        <!-- Month selection dropdown -->
        <form method="GET" v-if="totalView > 0">
            <div class="flex flex-col my-2 place-items-start sm:flex-row sm:place-items-center sm:my-8">
                <label for="monthSelect" class="text-gray-200 mr-2">Filter by Month:</label>
                <select name="month" id="monthSelect" @change="updateMonth($event.target.value)" class="py-2 px-4 rounded-md bg-gray-900 border-gray-900 border text-white">
                    <option value="all">All Months</option>
                    <option v-for="month in monthsList" :value="month" :key="month" :selected="month === selectedSetMonth">{{ month }}</option>
                </select>
            </div>
        </form>

        <apexchart
            v-if="chartOptions.xaxis.categories.length && chartData.length"
            ref="dashboardChart"
            :width="screenWidth <= 768 ? '100%' : '100%'"
            :height="chartHeight"
            type="bar"
            :options="chartOptions"
            :series="chartData"
            :auto-update-series="true"
            :auto-update-options="true"
        ></apexchart>

        <!-- Add some extra space here sofab button doesn't cover edit/delete button when now pagination is added -->
        <div class="h-16"></div>

        <!-- FAB Button to Add Expense -->
        <app-fab-button @click="openAddModal"></app-fab-button>

        <!-- Add Expense Modal -->
        <add-modal
            ref="addModal"
            :is-add-modal-open="isAddModalOpen"
            @update:is-add-modal-open="isAddModalOpen = $event"
            @expense-actions="handleExpenseActions"
        ></add-modal>

    </div>

</template>

<script>
import { ref, onMounted, computed } from 'vue';
// import { useToast } from 'vue-toastification';
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import AppLoader from "@/components/common/AppLoader.vue";
import AppFabButton from "@/components/common/AppFabButton";
import AddModal from "@/components/expenses/AddModal";

export default {
    components: {
        apexchart: VueApexCharts,
        AppLoader,
        AppFabButton,
        AddModal
    },
    setup() {
        // const toast = useToast();
        const getCurrentYearAndMonth = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
            return `${year}-${month}`;
        };
        const totalView = ref(0);
        const currMonth = ref(new Date().toLocaleString('en-US', { month: 'long' }));
        const screenWidth = ref(window.innerWidth);
        const isAddModalOpen = ref(false);
        const chartData = ref([]);
        const monthsList = ref([]);
        const subcategories = ref([]);
        const selectedSetMonth = ref(getCurrentYearAndMonth());
        const noDataMessage = ref('');
        const loading = ref(false);
        const expenseCountArray = ref([]); // Separate array to store expense_count
        const dashboardChart = ref(null);
        const addModal = ref(null);
        const chartOptions = ref({
            chart: {
                type: 'bar',
                id: 'subcat-bar',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: [], // Array to store category names (subcategory names)
                labels: {
                    style: {
                        colors: "#fff",
                        fontSize: '10px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#fff",
                        fontSize: '10px',
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '60%',
                    distributed: true, // This will remove the empty space between bars
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    // Check if the value is an exact number (has no decimal part)
                    if (Number.isInteger(val)) {
                        return val.toFixed(2); // Add .00 for exact numbers
                    }
                    return val; // For non-exact numbers, show the original value with two decimal places
                },
            },
            tooltip: {
                enabled: true,
                custom: function({ series, seriesIndex, dataPointIndex }) {
                    const expenseCount = expenseCountArray.value[seriesIndex][dataPointIndex]; // Get the expense_count from the separate array
                    return `<div class="py-4 px-8 bg-gray-900 border-0 ring-0">
                                <div class="text-white"><b>Value:</b> $${series[seriesIndex][dataPointIndex]}</div>
                                <div class="text-white"><b>Entries:</b> ${expenseCount}</div>
                            </div>`;
                }.bind(this), // Bind the custom function to the Vue instance
            },
            grid: {
                row: {
                    colors: ['transparent'],
                    opacity: 0,
                },
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent'],
            },
            legend: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                markers: {
                    width: 12,
                    height: 12,
                },
                itemMargin: {
                    horizontal: 12,
                    vertical: 0,
                },
                labels: {
                    colors: '#fff',
                },
            },
        });

        const chartHeight = computed(() => {
            return screenWidth.value <= 768 ? "400px" : "600px";
        });

        // Methods ------------------------------------------------------
        const fetchSubcategories = () => {
            axios.get('/backend/api/get-subcategories.php')
            .then((response) => {
                // Set the subcategories data to the response from the backend
                subcategories.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };

        const fetchMonths = async () => {
            try {
                const response = await axios.post('/backend/api/get-dashboard-months.php');
                monthsList.value = response.data;
            } catch (error) {
                console.error('Error fetching expenses:', error);
            }
        };

        const fillBarChart = async () => {
            // console.log('fillBarChart() called');
            loading.value = true;

            try {
                const response = await axios.post('/backend/api/get-dashboard-subcategories.php', {
                    selectedSetMonth: selectedSetMonth.value,
                }, {
                    responseType: 'json'
                });

                // console.log('API Response:', response.data);

                const totalExpenses = response.data.map(item => item.total_expenses);
                const expenseCount = response.data.map(item => item.expense_count);
                const subcategoryNames = response.data.map(item => item.subcategory_name);

                // console.log('Total Expenses:', totalExpenses);
                // console.log('Expense Count:', expenseCount);
                // console.log('Subcategory Names:', subcategoryNames);

                if (totalExpenses.length && subcategoryNames.length) {
                    chartData.value = [
                        {
                            name: 'Total Expenses',
                            data: totalExpenses,
                        }
                    ];

                    expenseCountArray.value = [expenseCount];
                    chartOptions.value.xaxis.categories = subcategoryNames;

                    setTimeout(() => {
                        // console.log('Updating chart with data:', chartData.value);
                        // console.log('Updating chart with options:', chartOptions.value);

                        // Ensure dashboardChart is initialized
                        if (dashboardChart.value) {
                            // console.log('dashboardChart:', dashboardChart.value);

                            if (typeof dashboardChart.value.updateSeries === 'function' && typeof dashboardChart.value.updateOptions === 'function') {
                                try {
                                    dashboardChart.value.updateSeries(chartData.value);
                                    dashboardChart.value.updateOptions(chartOptions.value);
                                } catch (updateError) {
                                    console.error('Error updating chart:', updateError);
                                }
                            } else {
                                console.error('updateSeries or updateOptions is not a function', dashboardChart.value);
                            }
                        } else {
                            console.error('dashboardChart is not initialized', dashboardChart.value);
                        }
                    }, 100);

                } else {
                    noDataMessage.value = 'No expenses to display. <br>Add a new expense.';
                }

            } catch (error) {
                console.error('Error fetching data or updating chart:', error);
            } finally {
                loading.value = false;
            }
        };

        const fillTotalExpense = async () => {
            try {
                const response = await axios.post('/backend/api/get-dashboard-total.php', {
                    selectedSetMonth: selectedSetMonth.value,
                });

                if (response.data > 0) {
                    totalView.value = response.data;
                } else {
                    noDataMessage.value = 'No expenses to display. <br>Add a new expense.';
                }

            } catch (error) {
                console.error(error);
            }
        };

        const openAddModal = () => {
            fetchSubcategories();
            isAddModalOpen.value = true;
        };

        const handleExpenseActions = () => {
            fillBarChart();
            fillTotalExpense();
        };

        const updateMonth = (selectedSetMonthValue) => {
            selectedSetMonth.value = selectedSetMonthValue;
            fillBarChart();
            fillTotalExpense();
        };

        onMounted(() => {
            fetchMonths();
            fillBarChart();
            fillTotalExpense();
        });

        return {
            totalView,
            currMonth,
            screenWidth,
            isAddModalOpen,
            chartData,
            monthsList,
            subcategories,
            selectedSetMonth,
            noDataMessage,
            loading,
            chartOptions,
            chartHeight,
            fetchMonths,
            fillBarChart,
            fillTotalExpense,
            getCurrentYearAndMonth,
            openAddModal,
            handleExpenseActions,
            updateMonth,
            dashboardChart,
            addModal
        };
    }
}
</script>
  
<style>
/* Add any custom styles specific to ExpensesView here */
</style>